import {
  AccountLevel,
  AccountType,
  AgencyVerificationStatus,
} from "@/enums/agencies";
import { ScriptType } from "@/enums/campaigns";
import { AgencyDataExternal, AgencyRestrictions } from "@/types/agencies";

export const DEFAULT_LARGE_RESTRICTION = 1000000;

const AGENCY_RESTRICTIONS_TRIAL: AgencyRestrictions = {
  campaign: {
    maxTotal: 2,
    maxConcurrency: 5,
    maxContacts: 10,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 100,
    maxUpload: 10000,
  },
  contactLists: {
    maxTotal: 2,
  },
  sequences: {
    maxTotal: 2,
  },
  redialingRules: {
    maxTotal: 2,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
};

const AGENCY_RESTRICTIONS_PAID: AgencyRestrictions = {
  campaign: {
    maxTotal: -1,
    maxConcurrency: 5,
    maxContacts: 100,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 100000,
    maxUpload: 10000,
  },
  contactLists: {
    maxTotal: -1,
  },
  sequences: {
    maxTotal: 2,
  },
  redialingRules: {
    maxTotal: 2,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
};

const AGENCY_RESTRICTIONS_VERIFIED: AgencyRestrictions = {
  campaign: {
    maxTotal: -1,
    maxConcurrency: 5,
    maxContacts: 1000,
    types: [ScriptType.Phone],
  },
  contacts: {
    maxTotal: 1000000,
    maxUpload: 20000,
  },
  contactLists: {
    maxTotal: -1,
  },
  sequences: {
    maxTotal: 5,
  },
  redialingRules: {
    maxTotal: 5,
  },
  account: {
    level: AccountLevel.SMB,
    type: [AccountType.Insurance],
  },
};

export const GetRestrictions = (
  agencyData: AgencyDataExternal | null,
  allowOverride = true
): AgencyRestrictions => {
  if (agencyData && agencyData.details?.restrictions && allowOverride) {
    let restrictions = agencyData.details.restrictions as AgencyRestrictions;

    if (restrictions.account === undefined) {
      restrictions.account = {
        level: AccountLevel.SMB,
        type: [AccountType.Insurance],
      };
    }

    if (restrictions.campaign.types === undefined) {
      restrictions.campaign.types = [ScriptType.Phone];
    }

    if (restrictions.sequences === undefined) {
      restrictions.sequences = {
        maxTotal: agencyData.stripe_id ? 5 : 2,
      };
    }

    if (restrictions.redialingRules === undefined) {
      restrictions.redialingRules = {
        maxTotal: agencyData.stripe_id ? 5 : 2,
      };
    }

    return restrictions;
  }

  let defaultRestrictions = AGENCY_RESTRICTIONS_TRIAL;

  if (agencyData && agencyData.stripe_id) {
    defaultRestrictions =
      agencyData.details?.agencyVerificationStatus ==
        AgencyVerificationStatus.Verified || agencyData.is_demo
        ? AGENCY_RESTRICTIONS_VERIFIED
        : AGENCY_RESTRICTIONS_PAID;
  }

  return defaultRestrictions;
};

export const GetRestrictionText = (
  restriction: number,
  defaultMax?: string
): string | number => {
  return restriction >= 0
    ? restriction
    : defaultMax || DEFAULT_LARGE_RESTRICTION;
};

export const GetRestrictionValue = (restriction: number): number => {
  return restriction >= 0 ? restriction : DEFAULT_LARGE_RESTRICTION;
};
