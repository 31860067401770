import { User } from "@auth0/auth0-react";

export const BASE_STRIPE_API = "https://api.stripe.com/v1";

export const IsAdmin = (user: User | undefined) => {
  return (
    user?.email?.endsWith("@lula.com") ||
    user?.email?.endsWith("@lula.is") ||
    user?.email?.endsWith("@lularentals.com")
  );
};
