export enum CampaignColumnTypes {
  Campaign = "campaignName",
  Type = "campaignType",
  Status = "status",
  Date = "createdAt",
  CreatedBy = "createdBy",
  Calls = "callsInQueue",
}

export enum CampaignStatus {
  Draft = "draft",
  Upcoming = "upcoming",
  Active = "active",
  Completed = "past",
}

export enum Campaign2Status {
  Draft = "draft",
  Active = "active",
  Stopped = "stopped",
  Archived = "archived",
}

export enum ScriptType {
  Phone = "phone",
  PhoneBlaster = "phoneBlaster",
  Email = "email",
  EmailBlaster = "emailBlaster",
  Text = "text",
  TextBlaster = "textBlaster",
}

export enum CampaignStatusDisplay {
  Draft = "Draft",
  Upcoming = "Ready to Start",
  Active = "Active",
  Completed = "Completed",
}

export enum CampaignStatusTextColor {
  Draft = "#000",
  Upcoming = "#000",
  Active = "#FFF",
  Completed = "#FFF",
}

export enum CampaignLeadColumnTypes {
  Name = "name",
  PhoneNumber = "phoneNumber",
  Status = "status",
  Attempts = "attempts",
  Detail = "detail",
  Date = "date",
}

export enum CampaignLeadStatus {
  Draft = "draft",
  Failed = "failed",
  NoAnswer = "no-answer",
  Answered = "answered",
  Busy = "busy",
  VoiceMail = "voicemail",
}
