export enum SubscriptionStatus {
  active = "active",
  trialing = "trialing",
  past_due = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  none = "none"
}
