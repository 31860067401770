import lscache from "lscache";

export enum CACHE_KEYS {
  DIRECTORY_CONTACTS = "DIRECTORY_CONTACTS",
}

//Call this when changes are made to the data
export function ClearCache(key: CACHE_KEYS) {
  lscache.remove(key);
}

//Call this on logout
export function ClearAllCache() {
  lscache.flush();
}
